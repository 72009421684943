export type FilterValueDTO<T extends string = string> = {
  value: T
  label: string
  meta?: Record<string, unknown>
}

export enum FilterID {
  DISTRICT_FILTER_ID = 'district',
  TEACHER_FILTER_ID = 'teacher',
  TRACKER_FILTER_ID = 'tracker',
  ASSESSMENT_FILTER_ID = 'material',
  SUBJECT_FILTER_ID = 'subject',
  CORE_FILTER_ID = 'core',
  CLASS_FILTER_ID = 'class',
  CURRICULUM_MAP_FILTER_ID = 'curriculum_map',
  RACE_FILTER_ID = 'race',
  GROUP_FILTER_ID = 'group',
  ETHNICITY_FILTER_ID = 'ethnicity',
  GENDER_FILTER_ID = 'gender',
  SCALE_FILTER_ID = 'scale',
  SCHOOL_FILTER_ID = 'school',
  SCHOOL_YEAR_FILTER_ID = 'school_year',
  ASSESSMENT_CATEGORY_FILTER_ID = 'assessment_category',
  NO_SCORE_FILTER_ID = 'no_score',
  ARCHIVE_DATA_FILTER_ID = 'archive_data',
  CREATED_BY_FILTER_ID = 'created_by',
  NAME_FILTER_ID = 'name',
  STANDARD_FILTER_ID = 'standard',
  ASSESSMENT_TYPES_FILTER_ID = 'assessment_types',
  SCALE_ID_FILTER_ID = 'scale_id',
  SUBJECT_IDS_FILTER_ID = 'subject_ids',
  CLASS_OBJECTIVE_IDS_FILTER_ID = 'class_objective_ids',
  CORE_IDS_FILTER_ID = 'core_ids',
}

export type FilterDTO<T = FilterValueDTO> = {
  id: FilterID
  values: T[]
  selectedValues: string[]
}
