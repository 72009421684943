export enum AddStudentOption {
  INDIVIDUAL = 'Add students individually',
  CSV = 'Import students from CSV file (spreadsheet)',
  COPY = 'Copy students from another tracker/class',
  SIS = 'Import students from SIS',
}

export const AddStudentOptions = [
  AddStudentOption.INDIVIDUAL,
  AddStudentOption.CSV,
  AddStudentOption.COPY,
  AddStudentOption.SIS,
]
