import type { FixedSizeNodeData, FixedSizeNodePublicState } from 'react-vtree'
import type { NodeComponentProps } from 'react-vtree/dist/lib/Tree'

export type FileId = number | string

export type OnSelectFileHandler = (id: string, checked: boolean) => void

export type FileTreeNodeMutable = {
  children?: FileTreeNode[]
  id: FileId
  name: string
  type?: string
  link?: string
  isOpenByDefault?: boolean
}

export type FileTreeNode = Readonly<FileTreeNodeMutable>

export type NodeData = FixedSizeNodeData &
  Readonly<{
    isFolder: boolean
    name: string
    nestingLevel: number
    type?: string
    link?: string
  }>

export type NodeMeta = Readonly<{
  nestingLevel: number
  node: FileTreeNode
}>

export enum NodesSelectStatusEnum {
  Checked = 'selected',
  Unchecked = 'unchecked',
  Indeterminate = 'indeterminate',
}

export type TreeData = {
  nodesState: NodesSelectStatus
  onSelectChange: OnSelectFileHandler
  onFileClick: (id: FileId) => void
}

export type NodeProps = Omit<
  NodeComponentProps<NodeData, FixedSizeNodePublicState<NodeData>>,
  'treeData'
> & {
  treeData: TreeData
}

export type NodesSelectStatus = { [key: FileId]: NodesSelectStatusEnum }
