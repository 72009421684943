import { AddToResourceModal } from '../Modals/AddToResourceModal'
import { NewTrackerModal } from '../../../classrooms/NewTrackerModal'
import { useState } from 'react'
import { useHasForeignStandards } from '../../../curriculum-map/contexts/CmapTrackerContext'

interface Props {
  addToTrackerModalOpen: boolean
  setAddToTrackerModalOpen: (isOpen: boolean) => void
}

export const AddToTrackerWorkflow = ({
  addToTrackerModalOpen,
  setAddToTrackerModalOpen,
}: Props) => {
  const [createTrackerModalOpen, setCreateTrackerModalOpen] = useState(false)
  const hasForeignStandards = useHasForeignStandards()

  const handleAddToTrackerModalCreateClick = () => {
    setAddToTrackerModalOpen(false)
    setCreateTrackerModalOpen(true)
  }

  const handleAddToTrackerModalDismiss = () => {
    setAddToTrackerModalOpen(false)
  }

  return (
    <>
      <AddToResourceModal
        open={addToTrackerModalOpen}
        handleDismiss={handleAddToTrackerModalDismiss}
        handleCreateClick={handleAddToTrackerModalCreateClick}
        resourceType="Tracker"
      />
      {!hasForeignStandards && (
        <NewTrackerModal
          isOpen={createTrackerModalOpen}
          setIsOpen={setCreateTrackerModalOpen}
        />
      )}
    </>
  )
}
