import { AbstractResource } from '../AbstractResource'

type ClassroomsCreateResponseBody = {
  classroomId: number
}

export type AssessmentInfo = {
  id: number | string
  title: string
}

type ClassroomsUpdateRequestBody = {
  id: number
  teachers: string[]
  ignoreParity: string
  classroom: {
    title: string
    folderColor: string
  }
}

type Student = {
  id: number
  name: string
  lname: string
}

type NonMatch = {
  nonSis: boolean
  student: Student
}

type ClassroomsUpdateResponseBody = {
  classroomId: number
  nonMatches?: NonMatch[]
}

class ClassroomsResource extends AbstractResource {
  _endpointUrl = '/classrooms'
  _snakeCaseKeys = true
  _camelizeKeys = true

  upload(data: FormData): Promise<ClassroomsCreateResponseBody> {
    return super.upload(data, '')
  }

  update(
    data: ClassroomsUpdateRequestBody,
    id: number
  ): Promise<ClassroomsUpdateResponseBody> {
    return super.update(data, id)
  }
}

export { ClassroomsResource, ClassroomsUpdateRequestBody }
