import { TextInput, Text } from '@instructure/ui'
import type { FormMessage } from '@instructure/ui-form-field'
import { Controller, useFormContext } from 'react-hook-form'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { useClassroom } from '../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

const INPUT_NAME = 'description'
const MAX_INPUT_CHAR_COUNT = 190

export const DescriptionInput = () => {
  const {
    watch,
    formState: { errors },
    trigger,
  } = useFormContext()
  const description = useClassroom()?.title || ''
  const inputLength = watch(INPUT_NAME)?.length || description.length

  const type = errors.description?.type ? 'error' : 'hint'
  const messages: FormMessage[] = [
    {
      type,
      text: (
        <Text>{`${inputLength}/${MAX_INPUT_CHAR_COUNT}`}</Text>
      ) as React.ReactNode,
    },
  ]
  return (
    <Controller
      name={INPUT_NAME}
      defaultValue={description}
      rules={{
        maxLength: MAX_INPUT_CHAR_COUNT,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <TextInput
          width="100%"
          renderLabel={'Description'}
          name={INPUT_NAME}
          id="description"
          {...selectorAttrs('tracker-form-description-input')}
          onChange={(event) => {
            onChange(event.target.value)
            trigger(INPUT_NAME)
          }}
          messages={messages}
          onBlur={onBlur}
          value={value}
          inputRef={ref}
        />
      )}
    />
  )
}
