import {
  ClassroomDTO,
  PredictiveReportsDTO,
  SchoolDTO,
  StudentDTO,
  TeacherDTO,
} from '../../../mc-resources/reports'
import {
  FileTreeNode,
  FileTreeNodeMutable,
} from '../../../mc-ui/collections/FilesTree/types'
import { sortFiles } from '../../../mc-ui/collections/FilesTree/utils'
import { useMemo } from 'react'

type FolderEntitiesList =
  | SchoolDTO[]
  | TeacherDTO[]
  | ClassroomDTO[]
  | StudentDTO[]

enum FolderType {
  School = 'school',
  Teacher = 'teacher',
  Classroom = 'classroom',
  Student = 'student',
}

const getFolderName = (
  folderEntitiesList: FolderEntitiesList,
  id: number
): string => {
  return folderEntitiesList.find((folderEntity) => folderEntity.id === id)?.name
}

/**
 * Generates FilesTree data
 * Converts incoming flat array of files and related meta data(schools, teachers etc.) to the files tree format expected be the FilesTree component
 *
 * @param data
 */
export const usePredictiveFilesTree = (
  data: PredictiveReportsDTO,
  /**
   * Whether or not to build School folders
   * If `false` - a school node(folder) will not be added to the file's ancestors tree.
   * This does not affect which files are shown.
   */
  { showSchools, showTeachers, showClassrooms, showStudents } = {
    showSchools: false,
    showTeachers: false,
    showClassrooms: false,
    showStudents: false,
  }
): { filesData: FileTreeNode[] } => {
  const filesData = useMemo<FileTreeNode[]>(
    function () {
      if (!data?.files) {
        return []
      }

      const tmpNodeList: {
        [id: number]: FileTreeNodeMutable & {
          isRoot?: boolean
        }
      } = {}

      /**
       * Get an existing node or create a new one and put it into the `tmpNodeList`
       * @param folderType
       * @param folderEntityId
       * @param folderEntitiesList
       * @param parentFolder
       */
      const getFolderNode = (
        folderType: FolderType,
        folderEntityId: number | null,
        folderEntitiesList: FolderEntitiesList,
        parentFolder?: FileTreeNodeMutable
      ): FileTreeNodeMutable | null => {
        if (!folderEntityId) {
          return null
        }

        let folderNodeId = `${folderType}_${folderEntityId}`

        if (parentFolder) {
          folderNodeId += `${parentFolder?.id}_`
        }

        if (!tmpNodeList[folderNodeId]) {
          tmpNodeList[folderNodeId] = {
            children: [],
            id: folderNodeId,
            name: getFolderName(folderEntitiesList, folderEntityId),
            isRoot: !parentFolder,
            isOpenByDefault: false,
          }
          if (parentFolder) {
            parentFolder.children.push(tmpNodeList[folderNodeId])
          }
        }

        return tmpNodeList[folderNodeId]
      }

      /**
       * Iterate over files array and create all needed ancestor folders
       * All folders levels are stored in the tmpNodeList
       * Also the root level files are stored in the tmpNodeList
       * All other(nested leafs) files are stored in the folder node they belong too
       *
       */
      data.files?.forEach((file) => {
        const school = showSchools
          ? getFolderNode(FolderType.School, file.schoolId, data.schools)
          : null

        const teacher = showTeachers
          ? getFolderNode(
              FolderType.Teacher,
              file.teacherId,
              data.teachers,
              school
            )
          : null

        const classroom = showClassrooms
          ? getFolderNode(
              FolderType.Classroom,
              file.classroomId,
              data.classrooms,
              teacher
            )
          : null

        const student = showStudents
          ? getFolderNode(
              FolderType.Student,
              file.studentId,
              data.students,
              classroom
            )
          : null

        /**
         * The order matters here
         * We need to find the first existing ancestor
         * File could be stored on any level
         */
        const parentNode = student || classroom || teacher || school

        const fileNode: FileTreeNodeMutable = {
          id: file.id,
          name: file.name,
          type: file.type,
          link: file.link,
        }

        if (parentNode) {
          parentNode.children.push(fileNode)
        } else {
          tmpNodeList[file.id] = { ...fileNode, isRoot: true }
        }
      })

      /**
       * Collect only `Root` Folders and Files.
       */
      const filesTreeData = Object.values(tmpNodeList).filter(
        (node) => node.isRoot
      )

      sortFiles(filesTreeData)

      return filesTreeData
    },
    [data, showSchools, showTeachers, showClassrooms, showStudents]
  )

  return { filesData }
}
