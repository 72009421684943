import { AbstractResource } from '../AbstractResource'

class OverviewReportsQueryResource extends AbstractResource {
  constructor(path) {
    super()
    this._endpointUrl = path
  }

  buildResourceUri = (params) => {
    const path = this._endpointUrl
    const queryStr = this._buildQueryString(params)
    return `${path}${queryStr}`
  }
}

export { OverviewReportsQueryResource }
