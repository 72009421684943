import {
  FileDrop,
  IconUploadLine,
  TextInput,
  Flex,
  View,
  Text,
  IconMsExcelLine,
  IconXLine,
  IconButton,
} from '@instructure/ui'
import { Controller, useFormContext } from 'react-hook-form'

export const StudentCSVUpload = () => {
  const { watch, setValue } = useFormContext()
  const { studentCsv } = watch()

  const FileDropRenderLabel = (
    <Flex
      direction="column"
      justifyItems="center"
      alignItems="center"
      height={'16.125rem'}
    >
      <IconUploadLine size="medium" color="secondary" />
      <View margin="large 0 xx-small 0">
        <Text size="x-large" color="secondary">
          Drag a file here, or
        </Text>
      </View>
      <Text color="brand">Choose a file to upload</Text>
    </Flex>
  )

  return studentCsv ? (
    <TextInput
      renderLabel="Upload CSV file"
      interaction="readonly"
      defaultValue={studentCsv.name}
      renderBeforeInput={
        <View margin="0 small 0 small">
          <IconMsExcelLine color="success" size="x-small" />
        </View>
      }
      themeOverride={{ padding: 0 }}
      renderAfterInput={
        <IconButton
          screenReaderLabel="Remove file"
          withBackground={false}
          withBorder={false}
          renderIcon={<IconXLine color="error" size="x-small" />}
          onClick={() => setValue('studentCsv', '')}
        />
      }
    />
  ) : (
    <Controller
      name="studentCsv"
      defaultValue={''}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, onBlur, ref } }) => (
        <FileDrop
          accept=".csv"
          width={'100%'}
          label="Upload CSV file"
          onDropAccepted={(files) => onChange(files[0])}
          shouldEnablePreview={true}
          onBlur={onBlur}
          ref={ref}
          renderLabel={FileDropRenderLabel}
          display="inline-block"
        />
      )}
    />
  )
}
