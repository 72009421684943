import { Controller } from 'react-hook-form'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { ColorPicker, View } from '@instructure/ui'
import { useClassroom } from '../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

const INPUT_NAME = 'folderColor'

export const FolderColorSelector = () => {
  const folderColor = useClassroom()?.folderColor || '#8B8B8B'

  return (
    <View width={'100%'}>
      <Controller
        name={INPUT_NAME}
        defaultValue={folderColor}
        rules={{
          // Regex checks for valid hex color code
          pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <ColorPicker
            label={'Folder Color'}
            placeholderText="Select a color"
            popoverMaxHeight="50vh"
            {...selectorAttrs('tracker-form-folder-color-input')}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            elementRef={ref}
            isRequired={true}
            colorMixerSettings={{
              popoverAddButtonLabel: 'Add',
              popoverCloseButtonLabel: 'Cancel',
              colorMixer: {
                rgbRedInputScreenReaderLabel: 'Input field for red',
                rgbGreenInputScreenReaderLabel: 'Input field for green',
                rgbBlueInputScreenReaderLabel: 'Input field for blue',
                rgbAlphaInputScreenReaderLabel: 'Input field for alpha',
                colorSliderNavigationExplanationScreenReaderLabel: `You are on a color slider. To navigate the slider left or right, use the 'A' and 'D' buttons respectively`,
                alphaSliderNavigationExplanationScreenReaderLabel: `You are on an alpha slider. To navigate the slider left or right, use the 'A' and 'D' buttons respectively`,
                colorPaletteNavigationExplanationScreenReaderLabel: `You are on a color palette. To navigate on the palette up, left, down or right, use the 'W', 'A', 'S' and 'D' buttons respectively`,
              },
              colorPreset: {
                label: 'Color Presets',
                colors: [
                  '#FFFFFF',
                  '#6B7780',
                  '#394B58',
                  '#111111',
                  '#EC041F',
                  '#D34022',
                  '#E31C5B',
                  '#CB34AF',
                  '#A34AE3',
                  '#6065E6',
                  '#007BC2',
                  '#1C8292',
                  '#02881F',
                ],
              },
            }}
          />
        )}
      />
    </View>
  )
}
