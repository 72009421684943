/**
 * Download the provided File to device
 *
 * @param {File} file
 */
const downloadFileToDevice = (file: File) => {
  const downloadUrl = URL.createObjectURL(file)
  const downloadElement = document.createElement('a')
  downloadElement.href = downloadUrl
  downloadElement.download = file.name

  downloadElement.click()
  URL.revokeObjectURL(downloadUrl)
}

const downloadFileByUrl = async (fileUrl, name) => {
  const anchor = document.createElement('a')
  anchor.href = fileUrl
  anchor.download = name
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export { downloadFileToDevice, downloadFileByUrl }
