import { useMutation } from '@tanstack/react-query'
import { ClassroomsResource } from '../../../mc-resources/classroom/ClassroomResource'

const classroomResource = ClassroomsResource.createInstance()

export const useCreateTracker = () => {
  return useMutation({
    mutationFn: (data: FormData) => classroomResource.upload(data),
    mutationKey: ['createTracker'],
  })
}
