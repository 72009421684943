import { SimpleSelect } from '@instructure/ui'
import { Controller } from 'react-hook-form'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { useSisOptions } from '../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

const INPUT_NAME = 'sisId'

export const SisSelector = () => {
  const defaultValue = 'specify'
  const sisOptions = useSisOptions()

  return (
    <Controller
      name={INPUT_NAME}
      defaultValue={defaultValue}
      rules={{
        minLength: 1,
        required: true,
        validate: (value) => value !== defaultValue,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <SimpleSelect
          renderLabel="Import from SIS"
          id={INPUT_NAME}
          value={value}
          onChange={(event: React.SyntheticEvent, data) => {
            onChange(data.value as string)
          }}
          onBlur={onBlur}
          inputRef={ref}
          {...selectorAttrs('new-tracker-form-sis-selector')}
        >
          <SimpleSelect.Option
            id={defaultValue}
            value={defaultValue}
            {...selectorAttrs('sis-selector-default-option')}
          >
            Specify
          </SimpleSelect.Option>
          {sisOptions.map(({ name, courseNumber, kidsCount, id }) => {
            return (
              <SimpleSelect.Option
                id={`${id}`}
                key={`${id}`}
                value={`${id}`}
                {...selectorAttrs(`sis-selector-option-sis-${id}`)}
              >
                {courseNumber
                  ? `${name} ${courseNumber} (${kidsCount})`
                  : `${name} (${kidsCount})`}
              </SimpleSelect.Option>
            )
          })}
        </SimpleSelect>
      )}
    />
  )
}
