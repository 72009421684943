import { Flex, View } from '@instructure/ui'
import { SelectedAlignments } from './SelectedAlignments'
import { AlignmentFieldsSelector } from '../../../mc-app/curriculum-map/components/AlignmentFieldsSelector'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { AddStudentsSelector } from './AddStudentsSelector'
import { SelectStudents } from './SelectStudents'
import { DescriptionInput } from './DescriptionInput'
import { FolderColorSelector } from './FolderColorSelector'
import {
  useCore,
  useGrade,
  useSubject,
} from '../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

export const NewTrackerForm = () => {
  const subject = useSubject()
  const core = useCore()
  const grade = useGrade()

  const borderSeperator = (
    <View
      as={'div'}
      borderWidth={'small 0 0 0'}
      width={'100%'}
      margin="0 0 medium"
    />
  )

  return (
    <Flex as={'div'} direction="column" alignItems="start" width="23rem">
      {subject && core && grade ? (
        <SelectedAlignments
          {...selectorAttrs('new-tracker-modal-selected-alignments')}
        />
      ) : (
        <AlignmentFieldsSelector />
      )}
      {borderSeperator}
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <DescriptionInput />
      </View>
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <FolderColorSelector />
      </View>
      {borderSeperator}
      <AddStudentsSelector />
      <SelectStudents />
    </Flex>
  )
}
