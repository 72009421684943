import { useMutation } from '@tanstack/react-query'
import { MaterialResource } from '../../../../mc-resources/materials'

export const useCloneMaterial = () => {
  return useMutation({
    mutationKey: ['clone-material'],
    mutationFn: (materialId: number | string) =>
      MaterialResource.createInstance().clone(`${materialId}`),
  })
}

export const useRevertMaterialToDraft = () => {
  return useMutation({
    mutationKey: ['revert-material-to-draft'],
    mutationFn: (materialId: number | string) =>
      MaterialResource.createInstance().revertToDraft(materialId),
  })
}
