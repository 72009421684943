import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as React from 'react'

import { Checkbox, View, Text } from '@instructure/ui'

import {
  FileId,
  FileTreeNode,
  NodeData,
  NodeMeta,
  NodesSelectStatus,
  NodesSelectStatusEnum,
  OnSelectFileHandler,
  TreeData,
} from './types'
import { useScroll } from '@use-gesture/react'
import type { TreeWalker } from 'react-vtree'
import { FixedSizeTree } from 'react-vtree'

import { useWindowSize } from 'react-use'

import { produce } from 'immer'

import { Node } from './TreeNode'
import { computeNodesSelectStatus, getNodeData } from './utils'
import { FilesTreeProvider, useMountTarget } from './context/FilesTreeContext'

type FilesTreeProps = {
  filesData: FileTreeNode[]
  onSelect?: (selected: FileId[]) => void
  onFileClick?: (id: FileId) => void
  renderActions?: JSX.Element
  /**
   * An element to add a scroll event listener to. If not provided - `window` is used
   * Should be used with modals
   */
  mountTarget?: HTMLElement
}

const FilesTree = ({
  filesData,
  onSelect,
  onFileClick,
  renderActions,
  mountTarget,
}: FilesTreeProps) => {
  const { width, height } = useWindowSize()

  const [selectedFiles, setSelectedFiles] = useState<FileId[]>([])
  const [fileIds, setFileIds] = useState<FileId[]>([])
  const [folderIds, setFolderIds] = useState<FileId[]>([])

  const [nodesState, setNodesSelectStatus] = useState<NodesSelectStatus>({})

  const onFileClickRef = useRef(onFileClick)
  const onSelectRef = useRef(onSelect)

  useEffect(() => {
    onFileClickRef.current = onFileClick
  }, [onFileClick])

  /**
   * Reset selection when files change(e.g. filtering)
   */
  useEffect(() => {
    setSelectedFiles([])
    setNodesSelectStatus({})
  }, [filesData])

  /**
   * Remember the latest `onSelect`
   *
   * So even if `onSelect` is not memoized - it shouldn't be a problem for <NodeContent />(wrapped with `memo`) component
   * which consumes memoized `itemData`
   */
  useEffect(() => {
    onSelectRef.current = onSelect
  }, [onSelect])

  /**
   * Calls `onSelect` when `selectedFiles` array was changed
   * Made this way(as effect instead of consecutive call after setSelectedFiles) to avoid extra dependency
   * in the `handleSelect`and related handlers
   *
   */
  useEffect(() => {
    onSelectRef.current?.(selectedFiles)
  }, [onSelectRef, selectedFiles])

  const handleSelect: OnSelectFileHandler = useCallback(
    (id, checked) => {
      setNodesSelectStatus(
        produce((draftNodesSelectStatus) => {
          computeNodesSelectStatus(
            filesData,
            id,
            checked
              ? NodesSelectStatusEnum.Checked
              : NodesSelectStatusEnum.Unchecked,
            draftNodesSelectStatus
          )
          setSelectedFiles(
            fileIds.filter(
              (fileId) =>
                draftNodesSelectStatus[fileId] === NodesSelectStatusEnum.Checked
            )
          )
        })
      )
    },
    [fileIds, filesData]
  )

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allSelected = {}
      const setNodeStatusChecked = (nodeId: FileId) => {
        allSelected[nodeId] = NodesSelectStatusEnum.Checked
      }

      fileIds.forEach(setNodeStatusChecked)
      folderIds.forEach(setNodeStatusChecked)

      setNodesSelectStatus(allSelected)
      setSelectedFiles(fileIds)
    } else {
      setNodesSelectStatus({})
      setSelectedFiles([])
    }
  }

  const handleFileClick = useCallback(
    (id: FileId) => {
      onFileClickRef.current?.(id)
    },
    [onFileClickRef]
  )

  /**
   * This object is passed to each tree node
   */
  const itemData = useMemo<TreeData>(
    () => ({
      nodesState,
      onSelectChange: handleSelect,
      onFileClick: handleFileClick,
    }),
    [nodesState, handleSelect, handleFileClick]
  )

  const treeWalker = useCallback(
    function* treeWalker(): ReturnType<TreeWalker<NodeData, NodeMeta>> {
      setFileIds(() => [])
      setFolderIds(() => [])
      for (let i = 0; i < filesData.length; i++) {
        yield getNodeData(filesData[i], 0)
      }

      while (true) {
        const parentMeta = yield

        if (!parentMeta.node.children) {
          setFileIds((fileIds) => [...fileIds, parentMeta.node.id])
        } else {
          setFolderIds((folderIds) => [...folderIds, parentMeta.node.id])
        }

        for (let i = 0; i < parentMeta.node.children?.length; i++) {
          yield getNodeData(
            parentMeta.node.children[i],
            parentMeta.nestingLevel + 1
          )
        }
      }
    },
    [filesData]
  )

  return (
    <>
      <View
        as={'div'}
        display={'flex'}
        borderWidth={'0 0 small 0'}
        css={{ alignItems: 'end' }}
      >
        <View as={'div'} padding={'0 0 small medium'}>
          <Checkbox
            label={
              <View padding={'0 0 0 x-small'}>
                <Text weight={'bold'}>
                  {`${selectedFiles.length} Selected files`}
                </Text>
              </View>
            }
            onChange={handleSelectAll}
            checked={selectedFiles.length > 0}
            indeterminate={
              selectedFiles.length > 0 && selectedFiles.length < fileIds.length
            }
            data-qa={'FilesTree_select_all'}
          />
        </View>
        {renderActions && (
          <View as={'div'} margin={'0 0 0 auto'} padding={'0 small small 0'}>
            {renderActions}
          </View>
        )}
      </View>
      {filesData.length > 0 && (
        <FilesTreeProvider target={mountTarget}>
          <FixedSizeTree
            height={height}
            treeWalker={treeWalker}
            outerElementType={OuterElement}
            itemSize={50}
            width={width}
            itemData={itemData}
            overscanCount={20}
          >
            {Node}
          </FixedSizeTree>
        </FilesTreeProvider>
      )}
    </>
  )
}

const OuterElement = forwardRef<
  HTMLDivElement,
  {
    onScroll: (event: {
      currentTarget: {
        clientWidth: number
        scrollHeight: number
        scrollLeft: number
        clientHeight: number
        scrollTop: number
        scrollWidth: number
      }
    }) => void
    children: ReactNode
  }
>(({ onScroll, children }, ref) => {
  const containerRef = useRef<HTMLDivElement | null>()

  const mountTarget = useMountTarget()

  const createRef = (node: HTMLDivElement | null) => {
    containerRef.current = node
    if (typeof ref === 'function') {
      ref(node)
    } else if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = node
    }
  }

  useScroll(
    () => {
      if (!(onScroll instanceof Function)) {
        return
      }
      const {
        clientWidth,
        clientHeight,
        scrollLeft,
        scrollTop,
        scrollHeight,
        scrollWidth,
      } = mountTarget || document.documentElement
      onScroll({
        currentTarget: {
          clientHeight,
          clientWidth,
          scrollLeft,
          scrollTop:
            scrollTop -
            (containerRef.current
              ? containerRef.current?.getBoundingClientRect().top + scrollTop
              : 0),
          scrollHeight,
          scrollWidth,
        },
      })
    },
    {
      target: mountTarget || window,
    }
  )

  return (
    <div
      style={{ position: 'relative', willChange: 'transform' }}
      ref={createRef}
    >
      {children}
    </div>
  )
})

export { FilesTree }
