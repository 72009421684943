import {
  Button,
  CloseButton,
  Flex,
  Heading,
  Modal,
  Spinner,
  Text,
} from '@instructure/ui'
import { selectorAttrs } from '../../../../mc-ui'
import { useCloneMaterial, useRevertMaterialToDraft } from '../hooks/mutations'

export interface CannotAddAssessmentModalProps {
  canClone: boolean
  canRevertToDraft: boolean
  isOpen: boolean
  materialId: number | string
  onDismiss: () => void
  resourceType: 'tracker' | 'curriculum map'
}

export const CannotAddAssessmentModal = ({
  canClone,
  canRevertToDraft,
  isOpen,
  materialId,
  onDismiss,
  resourceType,
}: CannotAddAssessmentModalProps) => {
  const clone = useCloneMaterial()
  const revert = useRevertMaterialToDraft()

  const handleClone = () => {
    clone.mutate(materialId, {
      onSuccess: ({ material }) => navigateToMaterialEdit(material.id),
      onError: (data) => console.error(data),
    })
  }

  const handleRevert = () => {
    revert.mutate(materialId, {
      onSuccess: () => navigateToMaterialEdit(materialId),
      onError: (data) => console.error(data),
    })
  }

  const handleDismiss = () => {
    onDismiss()
  }

  const navigateToMaterialEdit = (materialId: number | string) => {
    window.location.href = `/materials/${materialId}/edit`
  }

  const errorResolutionText = canRevertToDraft
    ? 'Either clone the assessment or revert it to a draft and remove the unavailable items to proceed.'
    : 'Clone the assessment and remove the unavailable items to proceed.'

  const disableButtons =
    clone.isLoading || clone.isSuccess || revert.isLoading || revert.isSuccess

  return (
    <Modal
      open={isOpen}
      onDismiss={handleDismiss}
      size="small"
      label="Cannot Add Assessment"
      {...selectorAttrs('cannot-add-assessment-modal')}
    >
      <Modal.Header
        themeOverride={(_, { spacing }) => ({
          padding: `${spacing.medium} ${spacing.small} ${spacing.medium} ${spacing.medium}`,
        })}
      >
        <Flex as="div" justifyItems="space-between" alignItems="center">
          <Heading>Cannot Add Assessment</Heading>
          <CloseButton
            onClick={handleDismiss}
            screenReaderLabel="Close"
            size="medium"
            {...selectorAttrs('cannot-add-assessment-modal-close-button')}
          />
        </Flex>
      </Modal.Header>

      <Modal.Body>
        <Flex direction="column" gap="mediumSmall">
          <Text>
            This assessment contains unavailable items and cannot be added to a{' '}
            {resourceType}.
          </Text>
          {canClone && <Text>{errorResolutionText}</Text>}
        </Flex>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={handleDismiss}
          margin="0 x-small 0 0"
          {...selectorAttrs('cannot-add-assessment-modal-cancel-button')}
        >
          Cancel
        </Button>
        {canRevertToDraft && (
          <Button
            onClick={handleRevert}
            interaction={disableButtons ? 'disabled' : 'enabled'}
            renderIcon={
              (revert.isLoading || revert.isSuccess) && (
                <LoadingSpinner renderTitle="Reverting to Draft" />
              )
            }
            margin="0 x-small 0 0"
            {...selectorAttrs('cannot-add-assessment-modal-revert-button')}
          >
            {revert.isLoading || revert.isSuccess
              ? 'Reverting to Draft'
              : 'Revert to Draft'}
          </Button>
        )}
        {canClone && (
          <Button
            onClick={handleClone}
            interaction={disableButtons ? 'disabled' : 'enabled'}
            color="primary"
            renderIcon={
              (clone.isLoading || clone.isSuccess) && (
                <LoadingSpinner inverse renderTitle="Cloning" />
              )
            }
            {...selectorAttrs('cannot-add-assessment-modal-clone-button')}
          >
            {clone.isLoading || clone.isSuccess ? 'Cloning' : 'Clone'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

interface LoadingSpinnerProps {
  inverse?: boolean
  renderTitle: string
}

const LoadingSpinner = ({
  renderTitle,
  inverse = false,
}: LoadingSpinnerProps) => (
  <Spinner
    renderTitle={renderTitle}
    size="x-small"
    variant={inverse ? 'inverse' : 'default'}
    themeOverride={(_, { colors }) => ({
      inverseColor: inverse ? colors.white : colors.brand,
    })}
  />
)
