import {useDelayedRender} from '../../utils/hooks/useDelayedRender'
import { ReactElement } from 'react'

const DelayedRender = ({
  delay,
  children,
}: {
  delay: number
  children: ReactElement
}) => {
  const delayRender = useDelayedRender(delay)

  return delayRender(() => children)
}

export {DelayedRender}
export {DelayedTransitionRender} from './DelayedTransitionRender'
