import { AbstractResource } from '../AbstractResource'
import { camelizeKeys, snakeCaseKeys } from '../../mc-sdk/case-utils'

export type FileDTO = Readonly<{
  id: number
  name: string
  type: string
  link: string
  schoolId?: number
  teacherId?: number
  classroomId?: number
  studentId?: number
}>

export type SchoolDTO = Readonly<{
  id: number
  name: string
}>

export type TeacherDTO = Readonly<{
  id: number
  name: string
}>

export type ClassroomDTO = Readonly<{
  id: number
  name: string
}>

export type StudentDTO = Readonly<{
  id: number
  name: string
}>

export type PredictiveReportsDTO = Readonly<{
  files: FileDTO[]
  schools: SchoolDTO[]
  teachers: TeacherDTO[]
  classrooms: ClassroomDTO[]
  students: StudentDTO[]
}>

export type ExportReportsDTO = {
  downloadLink?: string
}

class PredictiveReportsResource extends AbstractResource {
  _endpointUrl = '/api/internal/predictive_reports'

  list(data: {
    materialId: number
    classroomId?: number
  }): Promise<PredictiveReportsDTO> {
    return super.list(snakeCaseKeys(data)).then((data) => camelizeKeys(data))
  }

  exportReports(
    selectedFiles: (number | string)[],
    bundleName: string,
    queueProcessing = true
  ): Promise<ExportReportsDTO> {
    return this.fetchResource(`${this._endpointUrl}/export`, {
      method: 'POST',
      body: JSON.stringify({
        report_ids: selectedFiles,
        name: bundleName,
        queue_processing: queueProcessing,
      }),
    }).then((data) => camelizeKeys(data))
  }
}

export { PredictiveReportsResource }
