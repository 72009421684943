import PropTypes from 'prop-types'
import { Transition } from '@instructure/ui'

import { useDelayedValue } from '../../utils/hooks/useDelayedValue'

// This is essentially the same as `DelayedRender`, but uses a `Transition`
// component to do the showing
//
const DelayedTransitionRender = ({ delay = 400, children, ...transitionProps }) => {
  const transitionIn = !!useDelayedValue(delay, true, false)
  return (
    <Transition {...transitionProps} in={transitionIn}>
      { children }
    </Transition>
  )
}


DelayedTransitionRender.propTypes = {
  // Forwards all extra props to the `Transition` component
  ...Transition.propTypes,

  // Delay before starting to transition in, in milliseconds
  delay: PropTypes.number,

  // Render this element. `Transition` specifies that it must be one single element.
  children: PropTypes.element.isRequired,

  // The `in` prop will be ignored, and should not be given. (Specified here to
  // override the `Transition.propTypes` splat)
  in: PropTypes.oneOf([undefined]),
}

export { DelayedTransitionRender }
