import {
  FileTreeNode,
  FileTreeNodeMutable,
  NodeData,
  NodeMeta,
  NodesSelectStatus,
  NodesSelectStatusEnum,
} from './types'
import type { TreeWalkerValue } from 'react-vtree'

export const getNodeData = (
  node: FileTreeNode,
  nestingLevel: number
): TreeWalkerValue<NodeData, NodeMeta> => ({
  data: {
    id: node.id?.toString(),
    isFolder: node.children?.length > 0,
    isOpenByDefault: node.isOpenByDefault ?? true,
    name: node.name,
    type: node.type,
    link: node.link,
    nestingLevel,
  },
  nestingLevel,
  node,
})

/**
 *
 * Computes a node status by checking children status
 * This way the Folder node status changes depending on children
 *
 * @param node
 * @param nodesSelectStatus
 */
export const computeNodeSelectStatus = (
  node: FileTreeNode,
  nodesSelectStatus: NodesSelectStatus
): NodesSelectStatusEnum => {
  if (!node.children?.length) {
    return nodesSelectStatus[node.id]
  }

  let checked = 0
  let indeterminate = 0

  for (const child of node.children) {
    const childState = nodesSelectStatus[child.id]

    switch (childState) {
      case NodesSelectStatusEnum.Checked:
        checked++
        break
      case NodesSelectStatusEnum.Indeterminate:
        indeterminate++
        break
    }
  }

  if (indeterminate || (checked && checked < node.children.length)) {
    return NodesSelectStatusEnum.Indeterminate
  }

  if (checked === node.children.length) {
    return NodesSelectStatusEnum.Checked
  }

  return NodesSelectStatusEnum.Unchecked
}

export const setSelectStatusRecursively = (
  node: FileTreeNode,
  status: NodesSelectStatusEnum,
  draftNodesSelectStatus: NodesSelectStatus
) => {
  draftNodesSelectStatus[node.id] = status

  if (node.children) {
    for (const childNode of node.children) {
      setSelectStatusRecursively(childNode, status, draftNodesSelectStatus)
    }
  }
}

/**
 * Traverses a files tree and prepares a new `nodesSelectStatus` object by mutating the `draftNodesSelectStatus`
 *
 * @param rootNode
 * @param toggledNodeId
 * @param status
 * @param draftNodesSelectStatus
 */
export const computeNodesSelectStatus = (
  rootNode: FileTreeNode[] | FileTreeNode,
  toggledNodeId: string,
  status: NodesSelectStatusEnum,
  draftNodesSelectStatus: NodesSelectStatus = {}
): NodesSelectStatus => {
  const isLeaf = !Array.isArray(rootNode)
  const children = Array.isArray(rootNode) ? rootNode : rootNode.children

  if (isLeaf && rootNode.id.toString() === toggledNodeId) {
    setSelectStatusRecursively(rootNode, status, draftNodesSelectStatus)
    return draftNodesSelectStatus
  }

  if (!children) {
    return draftNodesSelectStatus
  } else {
    children.forEach((child) => {
      computeNodesSelectStatus(
        child,
        toggledNodeId,
        status,
        draftNodesSelectStatus
      )
      draftNodesSelectStatus[child.id] = computeNodeSelectStatus(
        child,
        draftNodesSelectStatus
      )
    })
  }

  return draftNodesSelectStatus
}

const cmpNodes = (a: FileTreeNodeMutable, b: FileTreeNodeMutable) => {
  const nodeTypeA = a.children?.length ? 1 : 0
  const nodeTypeB = b.children?.length ? 1 : 0
  let result

  if (nodeTypeA === nodeTypeB) {
    // both nodes are of the same type(folders or files)
    result = a.name.localeCompare(b.name)
  } else {
    // files appear before folders
    result = nodeTypeA - nodeTypeB
  }
  return result
}

/**
 *
 * Recursively sorts the filesTree (ASC).
 * Files go before folders
 * Mutates filesTree
 *
 * @param filesTree
 * @param cmpFn
 */
export const sortFiles = (
  filesTree: FileTreeNodeMutable[],
  cmpFn = cmpNodes
): void => {
  filesTree.sort(cmpFn)
  filesTree.forEach((node) => {
    if (node.children?.length > 0) {
      // eslint-disable-next-line no-param-reassign
      node.children = node.children.sort(cmpFn)
      sortFiles(node.children)
    }
  })
}