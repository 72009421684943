import { Controller } from 'react-hook-form'
import { SimpleSelect, View } from '@instructure/ui'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { AddStudentOptions, AddStudentOption } from '../helpers/constants'
import { useShowSisOption } from '../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

export const AddStudentsSelector = () => {
  const INPUT_NAME = 'addStudentOption'
  const showSisOption = useShowSisOption()

  return (
    <View as={'div'} margin="0 0 medium" width={'100%'}>
      <Controller
        name={INPUT_NAME}
        defaultValue={AddStudentOption.INDIVIDUAL}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <SimpleSelect
            renderLabel="Add students"
            id="addStudentOptionName"
            value={value}
            inputRef={ref}
            onBlur={onBlur}
            onChange={(event: React.SyntheticEvent, data) =>
              onChange(data.value as string)
            }
            {...selectorAttrs('add-students-selector')}
          >
            {AddStudentOptions.map((name) =>
              name !== AddStudentOption.SIS || showSisOption ? (
                <SimpleSelect.Option
                  key={name}
                  id={name}
                  value={name}
                  {...selectorAttrs(`students-selector-option-${name}`)}
                >
                  {name}
                </SimpleSelect.Option>
              ) : null
            )}
          </SimpleSelect>
        )}
      />
    </View>
  )
}
