import { AbstractResource } from '../../AbstractResource'
import { FilterDTO, FilterID } from './types'

class FiltersResource extends AbstractResource {
  _endpointUrl = '/api/internal/new_reports/filters'

  _camelizeKeys = true
  _snakeCaseKeys = true

  read: (id: FilterID) => Promise<FilterDTO>

  readWithQuery: (
    resourceId: FilterID,
    query: {
      subject?: string[]
      core?: string[]
      class?: string[]
      school?: string[]
      school_year?: string
      scale?: string
      material?: string[]
    }
  ) => Promise<FilterDTO>
}

export { FiltersResource }
