import { createContext, ReactNode, useContext, useState } from 'react'

type FilesTreeProviderProps = { children: ReactNode; target: HTMLElement }
type State = { target: HTMLElement }

const FilesTreeContext = createContext<State | undefined>(undefined)

const FilesTreeProvider = ({ children, target }: FilesTreeProviderProps) => {
  const [value] = useState({ target })

  return (
    <FilesTreeContext.Provider value={value}>
      {children}
    </FilesTreeContext.Provider>
  )
}

const useMountTarget = () => {
  const { target } = useContext(FilesTreeContext)
  return target
}

export { FilesTreeProvider, useMountTarget }
