import { useFormContext } from 'react-hook-form'
import { AddStudentOption } from '../helpers/constants'
import { View } from '@instructure/ui'
import { TrackerSelector } from './TrackerSelector'
import { StudentCSVUpload } from './StudentCSVUpload'
import { SisSelector } from './SisSelector'

export const SelectStudents = () => {
  const { watch } = useFormContext()
  const selectedAddStudentOption = watch('addStudentOption')

  if (selectedAddStudentOption === AddStudentOption.CSV) {
    return (
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <StudentCSVUpload />
      </View>
    )
  } else if (selectedAddStudentOption === AddStudentOption.COPY) {
    return (
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <TrackerSelector />
      </View>
    )
  } else if (selectedAddStudentOption === AddStudentOption.SIS) {
    return (
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <SisSelector />
      </View>
    )
  } else {
    return null
  }
}
