import { Flex, Spinner } from '@instructure/ui'

export const LoadingSpinner = () => {
  return (
    <Flex display="flex" alignItems="center" justifyItems="center">
      <Flex.Item>
        <Spinner renderTitle="Loading" size="large" margin="0 0 0 medium" />
      </Flex.Item>
    </Flex>
  )
}
