import { useState, useEffect, useCallback } from 'react'

// Utility hook to conditionally render modals (or anything else), while still
// giving them an opportunity to animate out.
//
// This is most useful for expensive elements which the user may seldom interact
// with; you may get better first-render performance by skipping it entirely
// until you need to show it.
//
// Params:
//
// - `open` (bool): whether the modal should be open. This should be the same as
//   what you pass to InstUI's modal.
//
// Returns Array of:
//
// - `shouldRender` (bool): whether to render the modal
// - `derender` (function): callback function, to be invoked when the modal has
//   finished animating out and can be torn down. With an InstUI Modal, this
//   corresponds to the `onExited` callback.
//
// Usage:
//
//     const [modalOpen, setModalOpen] = useState(false);  // for example
//     const [shouldRender, derender] = useHoldOpen(modalOpen);
//
//     return shouldRender &&
//       <Modal open={modalOpen} onExited={derender}>
//         ...
//       </Modal>
//
export const useHoldOpen = (open) => {
  const [ holdOpen, setHoldOpen ] = useState(open)
  const shouldRender = open || holdOpen

  useEffect(
    () => {
      // When we become open, set the doorstop to hold open. Since setting this
      // would cause a cascading render, defer it until the next cycle to
      // preserve responsiveness.
      if (open && !holdOpen) {
        const timer = setTimeout(() => setHoldOpen(true), 0)
        return () => clearTimeout(timer)
      }
    },
    // disabled on initial eslint-plugin-react-hooks configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  )

  const derender = useCallback(
    () => { setHoldOpen(false) },
    [ setHoldOpen ]
  )

  return [ shouldRender, derender ]
}
