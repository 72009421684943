import { Global } from '@instructure/emotion'

export const AddToCssOverrides = () => {
  return (
    <Global
      styles={{
        html: { height: 'auto' },
        body: { fontSize: '16px' },
        '#main': { margin: '0' },
        '#content': { padding: '0' },
      }}
    />
  )
}
