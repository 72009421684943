import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import {
  CmapTrackerContextValues,
  CmapTrackerContextProvider,
} from '../../../../mc-app/curriculum-map/contexts/CmapTrackerContext'
import {
  FeatureFlagMap,
  FeatureFlagsProvider,
} from '../../../common/contexts/FeatureFlagsContext'
import { ReactNode } from 'react'
import { AddToCssOverrides } from './AddToCssOverrides'

type Props = {
  children: ReactNode
  values: {
    contextValues: CmapTrackerContextValues
    featureFlags?: FeatureFlagMap
  }
}

export type AddToProviderProps = Omit<Props, 'children'>

export const AddToProvider = ({ children, values }: Props) => {
  const { contextValues, featureFlags } = values
  const queryClient = new QueryClient({})

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagsProvider values={featureFlags}>
        <CmapTrackerContextProvider cmapTrackerContext={contextValues}>
          <AddToCssOverrides />
          {children}
        </CmapTrackerContextProvider>
      </FeatureFlagsProvider>
    </QueryClientProvider>
  )
}
