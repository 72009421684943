import { useEffect, useState } from 'react'

// Returns different values before and after a delay has elapsed. Changing the
// delayed value, default value, or delay time will reset the timer.
//
// Params:
// - delay: milliseconds
// - value: any --- the desired value after the delay elapses
// - defaultValue: any, default undefined --- the value before the delay has
//   elapsed
//
export const useDelayedValue = <T>(
  delay: number,
  value: T,
  defaultValue: T = undefined
) => {
  const [delayed, setDelayed] = useState(defaultValue)

  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(value), delay)
    return () => {
      clearTimeout(timeout)
      setDelayed(defaultValue)
    }
  }, [delay, value, defaultValue])

  return delayed
}
