import { accepts } from '@instructure/ui-file-drop/lib/FileDrop/utils/accepts'

import {
  IconPdfLine,
  IconZippedLine,
  IconMsWordLine,
  IconMsPptLine,
  IconMsExcelLine,
  IconImageLine,
  IconDocumentLine,
  IconVideoCameraLine,
  IconAudioLine,
} from '@instructure/ui'
import type { SVGIconProps } from '@instructure/ui-svg-images'

const fileIconsMap = {
  '.pdf': IconPdfLine,
  '.tar,.rar,.zip,.7z,.gz,.arc,.bz,.bz2': IconZippedLine,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx,.odt':
    IconMsWordLine,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,.ppt,.pptx,.odp':
    IconMsPptLine,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx,.ods':
    IconMsExcelLine,
  'image/*': IconImageLine,
  'text/*,.rtf': IconDocumentLine,
  'video/*': IconVideoCameraLine,
  'audio/*': IconAudioLine,
}

const fileColorsMap = {
  '.pdf': 'error',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx,.ods':
    'success',
}

type Props = SVGIconProps & {
  file: {
    type: string
    name?: string
  }
  colorful?: boolean
}

const FileIcon = ({ file, colorful = false, ...iconProps }: Props) => {
  let FileIconComponent = IconDocumentLine
  let color

  Object.entries(fileIconsMap).map(([pattern, icon]) => {
    if (accepts(file, pattern)) {
      FileIconComponent = icon
    }
  })

  colorful &&
    Object.entries(fileColorsMap).map(([pattern, colorName]) => {
      if (accepts(file, pattern)) {
        color = colorName
      }
    })

  return <FileIconComponent {...iconProps} color={color} />
}

export default FileIcon
export { FileIcon }
