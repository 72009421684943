import {
  Modal,
  Flex,
  Text,
  CloseButton,
  Button,
  IconWarningLine,
  View,
} from '@instructure/ui'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import {
  useHasForeignStandards,
  useMaterial,
} from '../../../curriculum-map/contexts/CmapTrackerContext'

interface Props {
  open: boolean
  handleDismiss: () => void
  handleCreateClick: () => void
  resourceType: 'Curriculum Map' | 'Tracker'
}

export const AddToResourceModal = ({
  open,
  handleDismiss,
  handleCreateClick,
  resourceType,
}: Props) => {
  const materialName = useMaterial()?.title
  const hasForeignStandards = useHasForeignStandards()

  return (
    <Modal
      label={`Add to ${resourceType}`}
      open={open}
      onDismiss={handleDismiss}
      size="small"
      themeOverride={{
        smallMaxWidth: '28rem',
      }}
    >
      <Modal.Header>
        <Flex alignItems="center" justifyItems="space-between">
          <Flex gap="small">
            <IconWarningLine
              color="error"
              size="small"
              themeOverride={{ sizeSmall: '1.75rem' }}
            />
            <Text size="x-large">Add To {resourceType}</Text>
          </Flex>
          <CloseButton
            onClick={handleDismiss}
            screenReaderLabel="Close"
            size="medium"
            {...selectorAttrs('add-to-tracker-modal-close-button')}
          />
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Flex as="div" direction="column" gap="small">
          <Text size="medium" weight="bold">
            Standards Alignment
          </Text>
          <Text size="medium">
            This assessment is aligned to standards not contained in any of your
            active {resourceType}s.{' '}
            {hasForeignStandards
              ? `A ${resourceType} cannot be created for this assessment because it contains standards from another class or user-created custom standards.`
              : `To use the assessment's current alignment, create a new ${resourceType}.`}
          </Text>
          <View
            background="secondary"
            as="div"
            width={'100%'}
            borderRadius="medium"
          >
            <Flex
              as={'div'}
              direction="column"
              alignItems="start"
              padding="small"
            >
              <Flex.Item margin="0 0 xx-small">
                <Text>Selected assessment:</Text>
              </Flex.Item>
              <Flex.Item margin="0 0 xx-small">
                <Text weight="bold">{materialName}</Text>
              </Flex.Item>
            </Flex>
          </View>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="secondary"
          margin="xx-small"
          onClick={handleDismiss}
          {...selectorAttrs('add-to-tracker-modal-cancel-button')}
        >
          Cancel
        </Button>
        {!hasForeignStandards && (
          <Button
            type="submit"
            color="primary"
            margin="xx-small"
            {...selectorAttrs('add-to-tracker-modal-create-button')}
            onClick={handleCreateClick}
          >
            Create {resourceType}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
