import { Controller } from 'react-hook-form'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { SimpleSelect } from '@instructure/ui'
import {
  useBsClasses,
  useMcClasses,
} from '../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

const INPUT_NAME = 'trackerId'

export const TrackerSelector = () => {
  const defaultValue = 'specify'
  const mcClasses = useMcClasses()
  const bsClasses = useBsClasses()

  return (
    <Controller
      name={INPUT_NAME}
      defaultValue={defaultValue}
      rules={{
        minLength: 1,
        required: true,
        validate: (value) => value !== defaultValue,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <SimpleSelect
          renderLabel="Tracker"
          id={INPUT_NAME}
          value={value}
          onChange={(event: React.SyntheticEvent, data) => {
            onChange(data.value as string)
          }}
          onBlur={onBlur}
          inputRef={ref}
          {...selectorAttrs('new-tracker-form-tracker-selector')}
        >
          <SimpleSelect.Option
            id={defaultValue}
            value={defaultValue}
            {...selectorAttrs('tracker-selector-default-option')}
          >
            Specify
          </SimpleSelect.Option>
          {mcClasses.length > 0 ? (
            <SimpleSelect.Group renderLabel={'Mastery Connect'}>
              {mcClasses.map(({ name, id }) => {
                return (
                  <SimpleSelect.Option
                    id={`mc-${id}`}
                    key={`mc-${id}`}
                    value={`${id}`}
                    {...selectorAttrs(`tracker-selector-option-mc-${id}`)}
                  >
                    {name}
                  </SimpleSelect.Option>
                )
              })}
            </SimpleSelect.Group>
          ) : null}
          {bsClasses.length > 0 ? (
            <SimpleSelect.Group renderLabel={'Gradebook'}>
              {bsClasses.map(({ name, id }) => {
                return (
                  <SimpleSelect.Option
                    id={`bs-${id}`}
                    key={`bs-${id}`}
                    value={`${id}`}
                    {...selectorAttrs(`tracker-selector-option-bs-${id}`)}
                  >
                    {name}
                  </SimpleSelect.Option>
                )
              })}
            </SimpleSelect.Group>
          ) : null}
        </SimpleSelect>
      )}
    />
  )
}
