import {
  Modal,
  Flex,
  Text,
  CloseButton,
  Button,
  Spinner,
} from '@instructure/ui'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { FormProvider, useForm } from 'react-hook-form'
import { NewTrackerForm } from './NewTrackerForm'
import { NewTrackerFormData } from '../types'
import { AddStudentOption } from '../helpers/constants'
import { useCreateTracker } from '../hooks/useTrackerResource'
import { useState } from 'react'
import {
  useCore,
  useSubject,
  useGrade,
  useCurriculumMap,
  useMaterial,
} from '../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const NewTrackerModal = ({ isOpen, setIsOpen }: Props) => {
  const materialId = useMaterial()?.id
  const scaleId = useMaterial()?.scaleId
  const methods = useForm()
  const createTracker = useCreateTracker()
  const [isSaving, setIsSaving] = useState(false)

  const curriculumMap = useCurriculumMap()
  const subject = useSubject()
  const core = useCore()
  const grade = useGrade()

  const handleModalDismiss = () => {
    setIsOpen(false)
    methods.reset()
  }

  const formatRequest = ({
    subjectId,
    pathwayId,
    classId,
    description,
    folderColor,
    addStudentOption,
    studentCsv,
    trackerId,
    sisId,
  }: NewTrackerFormData): FormData => {
    const formData = new FormData()
    const addStudents =
      addStudentOption === AddStudentOption.CSV
        ? 'import'
        : addStudentOption === AddStudentOption.COPY
        ? 'copy'
        : addStudentOption === AddStudentOption.SIS
        ? 'api_import'
        : 'manual'

    formData.append(
      'classroom[subject_id]',
      subject ? `${subject.id}` : `${subjectId}`
    )
    formData.append(
      'classroom[pathway_id]',
      core ? `${core.id}` : `${pathwayId}`
    )
    formData.append(
      'classroom[class_objective_id]',
      grade ? `${grade.id}` : `${classId}`
    )
    formData.append('classroom[title]', `${description}`)
    formData.append('classroom[folder_color]', `${folderColor}`)
    formData.append('classroom[add_students]', `${addStudents}`)
    const id = trackerId ? `${trackerId}` : ''
    formData.append('classroom[id]', id)
    const sectionId = sisId ? `${sisId}` : ''
    formData.append('classroom[section_id]', sectionId)

    if (curriculumMap) {
      formData.append('classroom[curriculum_map]', `${curriculumMap.id}`)
    }

    if (addStudents === 'import') {
      formData.append('classroom[import_csv]', studentCsv)
    }
    if (scaleId) formData.append('classroom[scale_id]', `${scaleId}`)
    if (materialId) formData.append('material_id', `${materialId}`)

    return formData
  }

  const handleFormSubmit = (data: NewTrackerFormData) => {
    setIsSaving(true)
    createTracker.mutate(formatRequest(data), {
      onError: (error) => {
        console.error(error)
        setIsSaving(false)
      },
      onSuccess: ({ classroomId }) => {
        window.location.href = `/classrooms/${classroomId}`
      },
    })
  }

  const submitButtonInteraction = () => {
    const {
      formState: { isValid },
    } = methods
    return isValid ? 'enabled' : 'disabled'
  }

  const spinner = (
    <Spinner
      renderTitle="Saving"
      size="x-small"
      variant="inverse"
      themeOverride={(_, { colors }) => ({
        inverseColor: colors.white,
      })}
    />
  )

  const createButton = (
    <Button
      type="submit"
      color="primary"
      margin="xx-small"
      interaction={submitButtonInteraction()}
      {...selectorAttrs('new-tracker-modal-create-button')}
      onClick={methods.handleSubmit(handleFormSubmit)}
    >
      Create
    </Button>
  )

  const savingButton = (
    <Button
      type="button"
      color="primary"
      margin="xx-small"
      interaction={'disabled'}
      {...selectorAttrs('new-cmap-modal-saving-button')}
      renderIcon={spinner}
    >
      Saving
    </Button>
  )

  return (
    <FormProvider {...methods}>
      <Modal
        open={isOpen}
        label="Create Tracker Modal"
        onDismiss={() => setIsOpen(false)}
      >
        <Modal.Header>
          <Flex alignItems="center" justifyItems="space-between">
            <Text size="x-large">New Tracker</Text>
            <CloseButton
              onClick={handleModalDismiss}
              screenReaderLabel="Close"
              size="medium"
              {...selectorAttrs('new-tracker-modal-close-button')}
            />
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <NewTrackerForm />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="secondary"
            margin="xx-small"
            onClick={handleModalDismiss}
            {...selectorAttrs('new-tracker-modal-cancel-button')}
          >
            Cancel
          </Button>
          {isSaving ? savingButton : createButton}
        </Modal.Footer>
      </Modal>
    </FormProvider>
  )
}
